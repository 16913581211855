@import '../../node_modules/modern-normalize/modern-normalize.css';

@font-face {
	font-family: Muli;
	src: url('./muli.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Muli;
	src: url('./muli-bold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

$main-black: #21242b;

html,
body,
#root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	color: $main-black;
	font-family: -apple-system, Muli, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

a {
	text-decoration: none;
}

button {
	user-select: none;
	outline: none;
}

.active {
	opacity: 1 !important;
	transition: opacity 0.3s ease-in-out;
}

.rc-checkbox-inner {
	border-radius: 0;
	width: 20px;
	height: 20px;
}

.rc-checkbox-inner:after {
	width: 8px;
	height: 13px;
	top: 0px;
	left: 5px;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
	width: 8px;
	height: 13px;
	top: 0px;
	left: 5px;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
	border-color: $main-black;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
	border-color: $main-black;
}
.rc-checkbox-checked .rc-checkbox-inner {
	border-color: $main-black;
	background-color: $main-black;
}

@media print {
	.rc-checkbox-checked .rc-checkbox-inner {
		box-shadow: inset 0 0 0 16px $main-black;
	}
}

$loader-color: #21242b !default;
$loader-size: 20px !default;
$loader-height: 20px !default;
$loader-gap: 5px !default;
$loader-animation-duration: 1s !default;

@mixin spinner($size: $loader-size, $color: $loader-color, $duration: $loader-animation-duration, $gap: $loader-gap) {
	$unique-name: unique-id();
	width: $size;
	height: $size;
	position: relative;
	animation: #{'spinner-'}#{$unique-name} $duration ease infinite;

	@keyframes #{'spinner-'}#{$unique-name} {
		0%,
		100% {
			// top left
			box-shadow: -($size / 2 + $gap / 2) $size 0 $color,
				// top right
					($size / 2 + $gap / 2) $size 0 rgba($color, 0.1),
				// bottom right
					($size / 2 + $gap / 2) (($size * 2) + $gap) 0 rgba($color, 0.4),
				// bottom left
					- ($size / 2 + $gap / 2) (($size * 2) + $gap) 0 rgba($color, 0.7);
		}

		25% {
			box-shadow: -($size / 2 + $gap / 2) $size 0 rgba($color, 0.7), ($size / 2 + $gap / 2) $size 0 $color,
				($size / 2 + $gap / 2) (($size * 2) + $gap) 0 rgba($color, 0.1),
				-($size / 2 + $gap / 2) (($size * 2) + $gap) 0 rgba($color, 0.4);
		}

		50% {
			box-shadow: -($size / 2 + $gap / 2) $size 0 rgba($color, 0.4),
				($size / 2 + $gap / 2) $size 0 rgba($color, 0.7), ($size / 2 + $gap / 2) (($size * 2) + $gap) 0 $color,
				-($size / 2 + $gap / 2) (($size * 2) + $gap) 0 rgba($color, 0.1);
		}

		75% {
			box-shadow: -($size / 2 + $gap / 2) $size 0 rgba($color, 0.1),
				($size / 2 + $gap / 2) $size 0 rgba($color, 0.4),
				($size / 2 + $gap / 2) (($size * 2) + $gap) 0 rgba($color, 0.7),
				-($size / 2 + $gap / 2) (($size * 2) + $gap) 0 $color;
		}
	}
}

.spinner {
	@include spinner;
}
